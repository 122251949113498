import type { ReactNode } from 'react';
import styled from 'styled-components';

export const CardWrapper = styled.div<{
  children: ReactNode;
}>`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.media.desktop} {
    margin-bottom: 30px;
  }
`;

export const PaginationWrapper = styled.div`
  margin: 70px 0;
`;
